
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';

import OrdersTab from './usuariohabilidad.vue';
import AddressTab from './usuariopuesto.vue';

export default {
  components: {
    DxTabPanel, DxItem, OrdersTab, AddressTab,
  },
  props: {
    masterDetailData: {
      type: Object,
      default: () => ({}),
    },
  },
};
