import axios from 'axios';

export default class UsuarioPuestoService {

    getUsuarioPuesto(info) {            
        const url = process.env.VUE_APP_APIURL + `/api/usuariopuesto`;    
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

   
    createUsuarioPuesto(info){
        const url = process.env.VUE_APP_APIURL + `/api/usuariopuestoalta`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }

    deleteUsuarioPuesto(info){
        const url = process.env.VUE_APP_APIURL + `/api/usuariopuestobaja`;     
        return axios.post(url,info,{headers:{"Content-Type" : "application/json"}}).then(res=>res.data);
    }
}